<template>
    <inquiry-form-field :field-name="name" :min="min">
        <input v-model="quantity" type="number" @input="onInput" :name="name" :min="min" :max="max" :placeholder="min + '+'" required  />
    </inquiry-form-field>
</template>
<script>
export default {
    name: 'InquiryFormTicketQuantity',
    props: ['min', 'max', 'value'],
    data() {
        return { name: 'ticket_quantity', quantity: this.value };
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
