<template>
    <fieldset class="field">
        <label v-if="performances.length">Performance Time</label>
        <div class="field__outline field__outline--radio" v-if="performances.length">
            <div class="field__radio" v-for="performance in performances" :key="performance.id">
                <input v-model="selectedPerformanceID" type="radio" :id="'performance-' + performance.id" name="selected-performance" :value="performance.id">
                <label :for="'performance-'+performance.id">{{ performance.dateLabel }}</label>
            </div>
        </div>
    </fieldset>
</template>

<script>
export default {
    name: 'InquiryFormPerformanceTime',
    props: ['performances', 'value'],
    computed: {
        selectedPerformanceID: {
            get() {
                return this.value.id;
            },
            set(value) {
                this.$emit('input', this.currentPerformance(value));
            },
        },
    },
    methods: {
        currentPerformance(id) {
            return this.performances.find((performance) => performance.id === id);
        },
    },
};
</script>
