<template>
    <div>
        <button
            class="inquiry-form__sections"
            :disabled="!sections.length"
            @keydown.esc="closeSection"
            @click.prevent="onSectionClick"
        >
            {{ buttonLabel }}
        </button>
        <div v-show="displaySectionDrawer" class="inquiry-form__section-drawer">
            <table class="inquiry-form__table" v-if="sections.length" role="listbox">
                <tr class="inquiry-form__table-row inquiry-form__table-row--headers">
                    <th>Seating Section</th>
                    <th>Group Price</th>
                </tr>
                <tr
                    v-for="(row, index) in sections"
                    class="inquiry-form__table-row"
                    :key="index" @keydown.up.prevent="setFocus(index - 1)"
                    @keydown.down.prevent="setFocus(index + 1)"
                    @keydown.esc="closeSection"
                    @keydown.enter.prevent="onRowClick(index)"
                    @keydown.space.prevent="onRowClick(index)"
                    @keydown.tab.prevent @click="onRowClick(index)"
                    @focusout="focusout"
                    @focusin="focusin"
                    :aria-selected="(index === value) ? 'true' : 'false'"
                    role="option"
                    tabindex="0">
                    <td><span>Seating Section:</span> {{ row.seatingSection }}</td>
                    <td><span>Group Price:</span> {{ row.groupPrice && '$'}}{{ row.groupPrice }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InquiryFormSectionsSelectField',
    props: { value: [Number, String], sections: { type: Array, default: () => [] } },
    data() {
        return { displaySectionDrawer: false, hasFocus: false };
    },
    computed: {
        buttonLabel() {
            return this.sections.length && this.value !== ''
                ? this.sections[parseInt(this.value, 10)].seatingSection
                : 'Select';
        }
    },
    methods: {
        onRowClick(index) {
            this.$emit('input', index);
            this.closeSection();
        },
        onSectionClick(e) {
            if (
                !this.sections.length
                || e.target !== this.$el.querySelector('.inquiry-form__sections')
            ) {
                return;
            }

            this.displaySectionDrawer = !this.displaySectionDrawer;

            if (this.displaySectionDrawer) {
                const index = (this.value || 0) + 1;
                setTimeout(() => {
                    this.$el.querySelectorAll('.inquiry-form__table-row')[index].focus();
                }, 10);
            }
        },
        closeSection() {
            this.displaySectionDrawer = false;
            this.$el.querySelector('.inquiry-form__sections').focus();
        },
        focusout() {
            this.hasFocus = false;
            setTimeout(() => {
                if (!this.hasFocus) {
                    this.closeSection();
                }
            }, 10);
            this.$emit('blur')
        },
        focusin() {
            this.hasFocus = true;

            this.$emit('focus')
        },
        setFocus(index) {
            if (!(this.sections.length)) {
                return;
            }

            const rows = this.$el.querySelectorAll('.inquiry-form__table-row');
            const targetIndex = Math.min(Math.max(index, 0), this.sections.length);
            const row = rows[targetIndex + 1] || false;
            if (row) {
                row.focus();
            }
        },
    },
};
</script>
