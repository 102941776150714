<template>
    <div class="calendar-day__performances">
        <a v-for="performance in performances" :key="performance.id" :href="'#'+performance.pricingTierCode || null">
            {{ performance.time }} <span>{{ performance.pricingTierCode }}</span>
        </a>
    </div>
</template>

<script>
    export default {
        name: "PerformanceLinks",
        props: {
            day: Object,
            performances: Array
        }
    }
</script>
